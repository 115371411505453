<template>
  <div v-if="$can('read','jobs')">
    <template v-if="jobData">
      <b-row>
        <b-col cols="12" xl="12">
          <!--<jobs-view-job-info-card :job-data="jobData" :notes="notes" :file="jobData.attachments" :job-description="jobData.job_description"/>-->
          <div>
            <b-tabs content-class="mt-1">
              <b-tab title="Job Details" id="job-details">
                <b-card no-body class="card-browser-states mt-50">
                  <b-card-header>
                    <h4 class="mb-0 ml-1">
                      {{ jobData.job_id }} - {{ jobData.position_name }}
                      <span class="card-text" v-if="jobData.role"
                        >| {{ jobData.role }}</span
                      >
                      <span class="card-text" v-if="jobData.band && jobData.band !== 'undefined'"
                        >| {{ jobData.band }}</span
                      >
                    </h4>
                    <b-col md="6" xl="6" xs="6">
                      <div>
                        <div class="demo-inline-spacing">
                          <b-avatar
                            v-b-tooltip.hover
                            :text="jobData.initiated"
                            variant="light-secondary"
                            badge="I"
                            title="Initiated"
                          />
                          <b-avatar
                            v-b-tooltip.hover
                            :text="jobData.profileshared"
                            variant="light-info"
                            badge="P"
                            title="Profile-Shared"
                          />
                          <b-avatar
                            v-b-tooltip.hover
                            :text="jobData.inprocess"
                            variant="light-success"
                            badge="I"
                            title="Inprocess"
                          />
                          <b-avatar
                            v-b-tooltip.hover
                            :text="jobData.shortlisted"
                            variant="light-primary"
                            badge="S"
                            title="Shortlisted"
                          />

                          <b-avatar
                            v-b-tooltip.hover
                            :text="jobData.offered"
                            variant="light-primary"
                            badge="O"
                            title="Offered"
                          />
                          <b-avatar
                            v-b-tooltip.hover
                            :text="jobData.joined"
                            variant="light-primary"
                            badge="J"
                            title="Joined"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-card-header>

                  <!-- body -->
                  <b-card-body style="padding: 1rem !important">
                    <b-row style="color: #5e5873 !important; font-size: 0.9rem">
                      <b-col md="3" xl="3" xs="6" sm="6">
                        <div class="mb-1">
                          <h4 class="mb-0">
                            <span>{{ jobData.client }}</span>
                            |
                            <span>{{ jobData.domain }}</span>
                          </h4>
                          <span class="card-text">{{ jobData.status }}</span> |
                          <!-- <span class="card-text">{{ jobData.type }}</span> | -->
                          <span class="card-text">
                            Added on {{ jobData.createdAt }}</span
                          >
                          <br>
                          <span class="card-text">{{ jobData.type }}</span> | 
                          <span class="card-text">{{ jobData.category }}</span> | 
                          <span class="card-text">{{ jobData.payroll }}</span> |
                          <span class="card-text">{{ jobData.vertical }}</span>
                          <!--<span class="card-text"> {{ job.id }}</span>-->
                          <p>
                            <span class="card-text">{{
                              jobData.locations
                            }}</span>
                          </p>
                        </div>
                      </b-col>

                      <b-col md="2" xl="2" xs="6" sm="6">
                        <div class="mb-1">
                          <h6 class="mb-0">
                            Positions : {{ jobData.no_of_vacancies }}
                          </h6>
                          <span class="card-text"
                            >Open: {{ jobData.opened }} | Closed:
                            {{ jobData.closed }}</span
                          >
                        </div>
                      </b-col>
                      <b-col md="2" xl="2" xs="6" sm="6">
                        <div class="mb-1">
                          <b-avatar-group size="32px">
                            <b-avatar
                              v-for="(recruit, index) in jobData.recruiters"
                              :key="'B' + index"
                              v-b-tooltip.hover
                              class="pull-up"
                              :title="recruit.title"
                              variant="light-primary"
                              :text="avatarText(recruit.title)"
                            />
                          </b-avatar-group>
                        </div>
                      </b-col>
                      <b-col md="2" xl="2" xs="6" sm="6" v-if="jobData.sourcer">
                        <div class="mb-1">
                          <b-avatar-group size="32px">
                            <b-avatar
                              v-for="(source, index) in jobData.sourcer"
                              :key="'C' + index"
                              v-b-tooltip.hover
                              class="pull-up"
                              :title="source.title"
                              variant="light-primary"
                              :text="avatarText(source.title)"
                            />
                          </b-avatar-group>
                        </div>
                      </b-col>
                      <b-col md="2" xl="2" xs="6" sm="6">
                        <div
                          class="mb-1"
                        >
                          <h6 class="mb-0">
                            {{ jobData.account_mgr }}
                          </h6>
                        </div>
                      </b-col>
                      <div v-if="$can('read','jobs')"
                        style="margin-left: auto; margin-right: 0; text-align: right; padding-right: 10px;"
                      >
                        <router-link :to="'/apps/edit-job/' + jobData.id" v-if="$can('update','jobs')">
                          <feather-icon
                            icon="EditIcon"
                            size="18"
                            class="mr-50"
                          />
                        </router-link>
                        <b-dropdown v-if="$can('read','jobs')"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                          right
                        >
                          <template v-slot:button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="text-body align-middle mr-25"
                            />
                          </template>
                          <b-dropdown-item v-if="$can('update','job_status')"
                            @click="showModal(jobData.job_id, jobData.status)"
                          >
                            <feather-icon icon="Edit2Icon" class="mr-50" />
                            <span>Update Status</span>
                          </b-dropdown-item>
                          <b-dropdown-item v-if="$can('create','job_notes')"
                            @click="showModalNotes(jobData.id)"
                          >
                            <feather-icon icon="ClipboardIcon" class="mr-50" />
                            <span>Add Notes</span>
                          </b-dropdown-item>
                          <b-dropdown-item v-if="$can('read','jobs')"
                            @click="downloadJobtracker(jobData.id)"
                          >
                            <feather-icon icon="ClipboardIcon" class="mr-50" />
                            <span>Download Job Tracker</span>
                          </b-dropdown-item>
                          
                          <!-- Add new dropdown items -->
                          <b-dropdown-item v-if="$can('read','jobs')"
                            @click="downloadClientTracker(jobData.id)"
                          >
                            <feather-icon icon="ClipboardIcon" class="mr-50" />
                            <span>Download Today's Client Tracker</span>
                          </b-dropdown-item>

                          <b-dropdown-item v-if="$can('read','jobs')"
                            @click="showCustomDateModal"
                          >
                            <feather-icon icon="CalendarIcon" class="mr-50" />
                            <span>Download Custom Date Client Tracker</span>
                          </b-dropdown-item>
                          
                          <!--<b-dropdown-item @click="showModalNotes(job.job_id)">
                    <feather-icon icon="ClipboardIcon" class="mr-50" />
                    <span>Archive Job</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="showModalNotes(job.job_id)">
                    <feather-icon icon="ClipboardIcon" class="mr-50" />
                    <span>Clone Job</span>
                  </b-dropdown-item>-->
                        </b-dropdown>
                      </div>
                    </b-row>

                    <b-row
                      style="color: #5e5873 !important;font-size: 1rem;padding-top: 10px;"
                    >
                      <b-col md="3" xl="3" xs="6" class="mb-1">
                        <div class="d-flex flex-column ml-1">
                          <div class="mb-1">
                            <span class="text-muted"> Point of Contact </span>
                            <h6 class="mb-0">
                              {{ jobData.poc }}
                            </h6>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="3" xl="3" xs="6" class="mb-1">
                        <div class="d-flex flex-column ml-1">
                          <div class="mb-1">
                            <span class="text-muted"> Experience </span>
                            <h6 class="mb-0">
                              {{ jobData.min_exp }} -
                              {{ jobData.max_exp }} years
                            </h6>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="3" xl="3" xs="6" class="mb-1">
                        <div class="d-flex flex-column ml-1">
                          <div class="mb-1">
                            <span class="text-muted"> CTC </span>
                            <h6 class="mb-0">
                              ₹ {{ jobData.min_ctc }} - {{ jobData.max_ctc }} <span v-if="!blueCollar">Lacs</span>
                            </h6>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="3" xl="3" xs="6" class="mb-1">
                        <div class="d-flex flex-column ml-1">
                          <div class="mb-1">
                            <span class="text-muted"> Notice Period </span>
                            <h6 class="mb-0">
                              {{ jobData.notice_period }} Days
                            </h6>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="3" xl="3" xs="6" class="mb-1">
                        <div class="d-flex flex-column ml-1">
                          <div class="mb-1">
                            <span class="text-muted"> Target Date </span>
                            <h6 class="mb-0">
                              {{ jobData.target_date }}
                            </h6>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="3" xl="3" xs="6" class="mb-1">
                        <div class="d-flex flex-column ml-1">
                          <div class="mb-1">
                            <span class="text-muted"> Added By </span>
                            <h6 class="mb-0">
                              {{ jobData.created_by }}
                            </h6>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="6" xl="6" xs="6" class="mb-1">
                        <div class="d-flex flex-column ml-1">
                          <div class="mb-1">
                            <span class="text-muted"> Desired Skills </span>
                            <h6 class="mb-0">
                              {{ jobData.desired_skills }}
                            </h6>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>

                <!--<candidate-list-add-new-old :job-data="jobData"  @refreshParent="updateParent" />-->
                <div class="d-flex demo-inline-spacing align-items-center justify-content-end content-sidebar-header px-2 py-1" v-if="$can('create', 'candidates')">
    
                  
                <!-- <router-link
                  :to="{
                    name: 'apps-candidates-list-add',
                    params: { id: jobData.id },
                    query: { pname: jobData.position_name,
                              client: jobData.client,
                              locations: jobData.locations,
                              account_mgr: jobData.account_mgr,
                              attachments: jobData.attachments,
                              sourcer: jobData.sourcer,
                              min_ctc: jobData.min_ctc,
                              max_ctc: jobData.max_ctc,
                              min_exp: jobData.min_exp,
                              max_exp: jobData.max_exp,
                              notice_period: jobData.notice_period,
                              job_id: jobData.job_id
                            },
                  }"
                  >
                  <b-button @click="openAddJobPage" variant="outline-primary" class="px-2 mx-2 py-1">Add</b-button>
                </router-link> -->
                <b-button  @click="openAddJobSourcerPage" variant="outline-primary" class="px-2 py-1" v-if="$can('create','candidates')">Add Candidates</b-button>
                
                <candidate-list-add-new :key="new Date().getTime()" :job-info="jobData" @refreshParent="updateParent"/>
                <b-button  variant="primary" class="px-2 py-1" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-modal.modal-check-exist-in-db
                                v-b-tooltip.hover.bottom="'Check The Candidate in ATS Before Opening/Viewing the Contact Details in Naukri'">Check In DB</b-button>
       
              </div>
                
                <!--<candidate-list-add-new-old :job-data="jobData"  @refreshParent="updateParent" v-if="isRecruiter"/>-->
                
                  <!-- <app-collapse class="mt-1 mb-1" style="border-radius: 50px !important">
                    
                    <app-collapse-item title="Candidate Status">
                      <b-tabs content-class="mt-1 mb-2 mr-1 ml-1" v-if="fetchCandidates" >
                    <b-tab title="Qualified" >
                      <div class="row" style="max-height: 400px;overflow-y:scroll;">
                        <div class="col">
                          <b-list-group>
                            <b-list-group-item variant="secondary"
                              >Profile Shared ({{
                                approvedC
                                  ? approvedC.length
                                  : 0
                              }})</b-list-group-item
                            >
                          </b-list-group>
                          <b-list-group
                            v-for="c in approvedC"
                            :key="c.id"
                          >
                            <b-list-group-item
                              class="flex-column align-items-start"
                            >
                              <router-link
                                :to="{
                                  name: 'apps-candidates-view',
                                  params: { id: c.id, jobId: jobData.id },
                                  query: { position_name: jobData.position_name,
                                          client: jobData.client,
                                          attachments: jobData.attachments,
                                          recruiter: c.recruiter,
                                          locations: jobData.locations,
                                          sourcer: c.sourcer,
                                          am_name: jobData.account_mgr, job_id: jobData.job_id, job_id: jobData.job_id
                                  }
                                  
                                }"
                              >
                                <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-0">
                                    <u>{{ c.full_name }} </u>
                                  </h5>
                                </div>
                              </router-link>
                              <b-card-text class="mb-0" style="font-size: 0.9rem !important">
                                
                                {{ c.contact_no }}
                              </b-card-text>
                              
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                        <div class="col">
                          <b-list-group>
                            <b-list-group-item variant="dark"
                              >Screen Select ({{
                                screenSelectC
                                  ? screenSelectC.length
                                  : 0
                              }})</b-list-group-item
                            >
                          </b-list-group>
                          <b-list-group
                            v-for="c in screenSelectC"
                            :key="c.id"
                          >
                            <b-list-group-item
                              class="flex-column align-items-start"
                            >
                              <router-link
                                :to="{
                                  name: 'apps-candidates-view',
                                  params: { id: c.id, jobId: jobData.id },
                                  query: { position_name: jobData.position_name,
                                          client: jobData.client,
                                          attachments: jobData.attachments,
                                          recruiter: c.recruiter,
                                          locations: jobData.locations,
                                          sourcer: c.sourcer,
                                          am_name: jobData.account_mgr, job_id: jobData.job_id
                                  }
                                }"
                              >
                                <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-0">
                                    <u>{{ c.full_name }} </u>
                                  </h5>
                                </div>
                              </router-link>
                              <b-card-text class="mb-0" style="font-size: 0.9rem !important">
                                
                                {{ c.contact_no }}
                              </b-card-text>
                              
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                        <div class="col">
                          <b-list-group>
                            <b-list-group-item variant="info"
                              >In Process ({{
                                inprocessC
                                  ? inprocessC.length
                                  : 0
                              }})</b-list-group-item
                            >
                          </b-list-group>
                          <b-list-group
                            v-for="c in inprocessC"
                            :key="c.id"
                          >
                            <b-list-group-item
                              class="flex-column align-items-start"
                            >
                              <router-link
                                :to="{
                                  name: 'apps-candidates-view',
                                  params: { id: c.id, jobId: jobData.id },
                                  query: { position_name: jobData.position_name,
                                          client: jobData.client,
                                          attachments: jobData.attachments,
                                          recruiter: c.recruiter,
                                          locations: jobData.locations,
                                          sourcer: c.sourcer,
                                          am_name: jobData.account_mgr, job_id: jobData.job_id
                                  }
                                }"
                              >
                                <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-0">
                                    <u>{{ c.full_name }} </u>
                                  </h5>
                                </div>
                              </router-link>
                              <b-card-text class="mb-0" style="font-size: 0.9rem !important">
                                
                                {{ c.contact_no }}
                              </b-card-text>
                              
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                        <div class="col">
                          <b-list-group>
                            <b-list-group-item variant="warning"
                              >On Hold ({{
                                onholdC
                                  ? onholdC.length
                                  : 0
                              }})</b-list-group-item
                            >
                          </b-list-group>
                          <b-list-group
                            v-for="c in onholdC"
                            :key="c.id"
                          >
                            <b-list-group-item
                              class="flex-column align-items-start"
                            >
                              <router-link
                                :to="{
                                  name: 'apps-candidates-view',
                                  params: { id: c.id, jobId: jobData.id },
                                  query: { position_name: jobData.position_name,
                                          client: jobData.client,
                                          attachments: jobData.attachments,
                                          recruiter: c.recruiter,
                                          locations: jobData.locations,
                                          sourcer: c.sourcer,
                                          am_name: jobData.account_mgr, job_id: jobData.job_id
                                  }
                                }"
                              >
                                <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-0">
                                    <u>{{ c.full_name }} </u>
                                  </h5>
                                </div>
                              </router-link>
                              <b-card-text class="mb-0" style="font-size: 0.9rem !important">
                                
                                {{ c.contact_no }}
                              </b-card-text>
                              
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                        <div class="col">
                          <b-list-group>
                            <b-list-group-item variant="primary"
                              >Shortlisted ({{
                                shortlistedC
                                  ? shortlistedC.length
                                  : 0
                              }})</b-list-group-item
                            >
                          </b-list-group>
                          <b-list-group
                            v-for="c in shortlistedC"
                            :key="c.id"
                          >
                            <b-list-group-item
                              class="flex-column align-items-start"
                            >
                              <router-link
                                :to="{
                                  name: 'apps-candidates-view',
                                  params: { id: c.id, jobId: jobData.id },
                                  query: { position_name: jobData.position_name,
                                          client: jobData.client,
                                          attachments: jobData.attachments,
                                          recruiter: c.recruiter,
                                          locations: jobData.locations,
                                          sourcer: c.sourcer,
                                          am_name: jobData.account_mgr, job_id: jobData.job_id
                                  }
                                }"
                              >
                                <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-0">
                                    <u>{{ c.full_name }} </u>
                                  </h5>
                                </div>
                              </router-link>
                              <b-card-text class="mb-0" style="font-size: 0.9rem !important">
                                
                                {{ c.contact_no }}
                              </b-card-text>
                              
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                        <div class="col">
                          <b-list-group>
                            <b-list-group-item variant="success"
                              >Offered ({{
                                offeredC
                                  ? offeredC.length
                                  : 0
                              }})</b-list-group-item
                            >
                          </b-list-group>
                          <b-list-group
                            v-for="c in offeredC"
                            :key="c.id"
                          >
                            <b-list-group-item
                              class="flex-column align-items-start"
                            >
                              <router-link
                                :to="{
                                  name: 'apps-candidates-view',
                                  params: { id: c.id, jobId: jobData.id },
                                  query: { position_name: jobData.position_name,
                                          client: jobData.client,
                                          attachments: jobData.attachments,
                                          recruiter: c.recruiter,
                                          locations: jobData.locations,
                                          sourcer: c.sourcer,
                                          am_name: jobData.account_mgr, job_id: jobData.job_id
                                  }
                                }"
                              >
                                <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-0">
                                    <u>{{ c.full_name }} </u>
                                  </h5>
                                </div>
                              </router-link>
                              <b-card-text class="mb-0" style="font-size: 0.9rem !important">
                                
                                {{ c.contact_no }}
                              </b-card-text>
                              
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                        <div class="col">
                          <b-list-group>
                            <b-list-group-item variant="success"
                              >Joined ({{
                                joinedC
                                  ? joinedC.length
                                  : 0
                              }})</b-list-group-item
                            >
                          </b-list-group>
                          <b-list-group
                            v-for="c in joinedC"
                            :key="c.id"
                          >
                            <b-list-group-item
                              class="flex-column align-items-start"
                            >
                              <router-link
                                :to="{
                                  name: 'apps-candidates-view',
                                  params: { id: c.id, jobId: jobData.id },
                                  query: { position_name: jobData.position_name,
                                          client: jobData.client,
                                          attachments: jobData.attachments,
                                          recruiter: c.recruiter,
                                          locations: jobData.locations,
                                          sourcer: c.sourcer,
                                          am_name: jobData.account_mgr, job_id: jobData.job_id
                                  }
                                }"
                              >
                                <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-0">
                                    <u>{{ c.full_name }} </u>
                                  </h5>
                                </div>
                              </router-link>
                              <b-card-text class="mb-0" style="font-size: 0.9rem !important">
                                
                                {{ c.contact_no }}
                              </b-card-text>
                              
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Dis-Qualified">
                      <div class="row" style="max-height: 400px;overflow-y:scroll;">
                        <div class="col">
                          <b-list-group>
                            <b-list-group-item variant="secondary"
                              >Review Reject ({{
                                reviewrejectC
                                  ? reviewrejectC.length
                                  : 0
                              }})</b-list-group-item
                            >
                          </b-list-group>
                          <b-list-group
                            v-for="c in reviewrejectC"
                            :key="c.id"
                          >
                            <b-list-group-item
                              class="flex-column align-items-start"
                            >
                              <router-link
                                :to="{
                                  name: 'apps-candidates-view',
                                  params: { id: c.id, jobId: jobData.id },
                                  query: { position_name: jobData.position_name,
                                          client: jobData.client,
                                          attachments: jobData.attachments,
                                          recruiter: c.recruiter,
                                          locations: jobData.locations,
                                          sourcer: c.sourcer,
                                          am_name: jobData.account_mgr, job_id: jobData.job_id
                                  }
                                }"
                              >
                                <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-0">
                                    <u>{{ c.full_name }} </u>
                                  </h5>
                                </div>
                              </router-link>
                              <b-card-text class="mb-0" style="font-size: 0.9rem !important">
                                
                                {{ c.contact_no }}
                              </b-card-text>
                              
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                        <div class="col">
                          <b-list-group>
                            <b-list-group-item variant="secondary"
                              >Not Relevant ({{
                                notrelevantC 
                                  ? notrelevantC.length
                                  : 0
                              }})</b-list-group-item
                            >
                          </b-list-group>
                          <b-list-group
                            v-for="c in notrelevantC"
                            :key="c.id"
                          >
                            <b-list-group-item
                              class="flex-column align-items-start"
                            >
                              <router-link
                                :to="{
                                  name: 'apps-candidates-view',
                                  params: { id: c.id, jobId: jobData.id },
                                  query: { position_name: jobData.position_name,
                                          client: jobData.client,
                                          attachments: jobData.attachments,
                                          recruiter: c.recruiter,
                                          locations: jobData.locations,
                                          sourcer: c.sourcer,
                                          am_name: jobData.account_mgr, job_id: jobData.job_id
                                  }
                                }"
                              >
                                <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-0">
                                    <u>{{ c.full_name }} </u>
                                  </h5>
                                </div>
                              </router-link>
                              <b-card-text class="mb-0" style="font-size: 0.9rem !important">
                                
                                {{ c.contact_no }}
                              </b-card-text>
                              
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                        <div class="col">
                          <b-list-group>
                            <b-list-group-item variant="secondary"
                              >Not Interested ({{
                                notinterestedC 
                                  ? notinterestedC.length
                                  : 0
                              }})</b-list-group-item
                            >
                          </b-list-group>
                          <b-list-group
                            v-for="c in notinterestedC"
                            :key="c.id"
                          >
                            <b-list-group-item
                              class="flex-column align-items-start"
                            >
                              <router-link
                                :to="{
                                  name: 'apps-candidates-view',
                                  params: { id: c.id, jobId: jobData.id },
                                  query: { position_name: jobData.position_name,
                                          client: jobData.client,
                                          attachments: jobData.attachments,
                                          recruiter: c.recruiter,
                                          locations: jobData.locations,
                                          sourcer: c.sourcer,
                                          am_name: jobData.account_mgr, job_id: jobData.job_id
                                  }
                                }"
                              >
                                <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-0">
                                    <u>{{ c.full_name }} </u>
                                  </h5>
                                </div>
                              </router-link>
                              <b-card-text class="mb-0" style="font-size: 0.9rem !important">
                                
                                {{ c.contact_no }}
                              </b-card-text>
                              
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                        <div class="col">
                          <b-list-group>
                            <b-list-group-item variant="warning"
                              >Screen Reject ({{
                                screenrejectC
                                  ? screenrejectC.length
                                  : 0
                              }})</b-list-group-item
                            >
                          </b-list-group>
                          <b-list-group
                            v-for="c in screenrejectC"
                            :key="c.id"
                          >
                            <b-list-group-item
                              class="flex-column align-items-start"
                            >
                              <router-link
                                :to="{
                                  name: 'apps-candidates-view',
                                  params: { id: c.id, jobId: jobData.id },
                                  query: { position_name: jobData.position_name,
                                          client: jobData.client,
                                          attachments: jobData.attachments,
                                          recruiter: c.recruiter,
                                          locations: jobData.locations,
                                          sourcer: c.sourcer,
                                          am_name: jobData.account_mgr, job_id: jobData.job_id
                                  }
                                }"
                              >
                                <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-0">
                                    <u>{{ c.full_name }} </u>
                                  </h5>
                                </div>
                              </router-link>
                              <b-card-text class="mb-0" style="font-size: 0.9rem !important">
                                
                                {{ c.contact_no }}
                              </b-card-text>
                              
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                        <div class="col">
                          <b-list-group>
                            <b-list-group-item variant="warning"
                              >Duplicate ({{
                                duplicateC
                                  ? duplicateC.length
                                  : 0
                              }})</b-list-group-item
                            >
                          </b-list-group>
                          <b-list-group
                            v-for="c in duplicateC"
                            :key="c.id"
                          >
                            <b-list-group-item
                              class="flex-column align-items-start"
                            >
                              <router-link
                                :to="{
                                  name: 'apps-candidates-view',
                                  params: { id: c.id, jobId: jobData.id },
                                  query: { position_name: jobData.position_name,
                                          client: jobData.client,
                                          attachments: jobData.attachments,
                                          recruiter: c.recruiter,
                                          locations: jobData.locations,
                                          sourcer: c.sourcer,
                                          am_name: jobData.account_mgr, job_id: jobData.job_id
                                  }
                                }"
                              >
                                <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-0">
                                    <u>{{ c.full_name }} </u>
                                  </h5>
                                </div>
                              </router-link>
                              <b-card-text class="mb-0" style="font-size: 0.9rem !important">
                                
                                {{ c.contact_no }}
                              </b-card-text>
                              
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                        <div class="col">
                          <b-list-group>
                            <b-list-group-item variant="danger"
                              >Rejected ({{
                                rejectedC
                                  ? rejectedC.length
                                  : 0
                              }})</b-list-group-item
                            >
                          </b-list-group>
                          <b-list-group
                            v-for="c in rejectedC"
                            :key="c.id"
                          >
                            <b-list-group-item
                              class="flex-column align-items-start"
                            >
                              <router-link
                                :to="{
                                  name: 'apps-candidates-view',
                                  params: { id: c.id, jobId: jobData.id },
                                  query: { position_name: jobData.position_name,
                                          client: jobData.client,
                                          attachments: jobData.attachments,
                                          recruiter: c.recruiter,
                                          locations: jobData.locations,
                                          sourcer: c.sourcer,
                                          am_name: jobData.account_mgr, job_id: jobData.job_id
                                  }
                                }"
                              >
                                <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-0">
                                    <u>{{ c.full_name }} </u>
                                  </h5>
                                </div>
                              </router-link>
                              <b-card-text class="mb-0" style="font-size: 0.9rem !important">
                                
                                {{ c.contact_no }}
                              </b-card-text>
                              
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                        <div class="col">
                          <b-list-group>
                            <b-list-group-item variant="danger"
                              >Not Offered ({{
                                notofferedC
                                  ? notofferedC.length
                                  : 0
                              }})</b-list-group-item
                            >
                          </b-list-group>
                          <b-list-group
                            v-for="c in notofferedC"
                            :key="c.id"
                          >
                            <b-list-group-item
                              class="flex-column align-items-start"
                            >
                              <router-link
                                :to="{
                                  name: 'apps-candidates-view',
                                  params: { id: c.id, jobId: jobData.id },
                                  query: { position_name: jobData.position_name,
                                          client: jobData.client,
                                          attachments: jobData.attachments,
                                          recruiter: c.recruiter,
                                          locations: jobData.locations,
                                          sourcer: c.sourcer,
                                          am_name: jobData.account_mgr, job_id: jobData.job_id
                                  }
                                }"
                              >
                                <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-0">
                                    <u>{{ c.full_name }} </u>
                                  </h5>
                                </div>
                              </router-link>
                              <b-card-text class="mb-0" style="font-size: 0.9rem !important">
                                
                                {{ c.contact_no }}
                              </b-card-text>
                             
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                        <div class="col">
                          <b-list-group>
                            <b-list-group-item variant="danger"
                              >Offer Drop ({{
                                offerdropC
                                  ? offerdropC.length
                                  : 0
                              }})</b-list-group-item
                            >
                          </b-list-group>
                          <b-list-group
                            v-for="c in offerdropC"
                            :key="c.id"
                          >
                            <b-list-group-item
                              class="flex-column align-items-start"
                            >
                              <router-link
                                :to="{
                                  name: 'apps-candidates-view',
                                  params: { id: c.id, jobId: jobData.id },
                                  query: { position_name: jobData.position_name,
                                          client: jobData.client,
                                          attachments: jobData.attachments,
                                          recruiter: c.recruiter,
                                          locations: jobData.locations,
                                          sourcer: c.sourcer,
                                          am_name: jobData.account_mgr, job_id: jobData.job_id
                                  }
                                }"
                              >
                                <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-0">
                                    <u>{{ c.full_name }} </u>
                                  </h5>
                                </div>
                              </router-link>
                              <b-card-text class="mb-0" style="font-size: 0.9rem !important">
                                
                                {{ c.contact_no }}
                              </b-card-text>
                              
                            </b-list-group-item>
                          </b-list-group>
                        </div>

                        
                      </div>
                    </b-tab>
                    
                    
                    <b-tab>
                      <template #title>
                        <span>New</span>
                        <feather-icon v-if="initiatedC"
                          :badge="initiatedC ? initiatedC.length : 0"
                          badge-classes="bg-danger"
                          class="text-body"
                          icon="UserIcon"
                          size="16"
                        />
                      </template>
                      
                      <div class="row" style="max-height: 400px;overflow-y:scroll;">
                        <div class="col">
                          <b-list-group>
                            <b-list-group-item variant="info"
                              >Initiated ({{
                                initiatedC ? initiatedC.length : 0
                              }})
                              
                            </b-list-group-item>
                          </b-list-group>
                          <b-list-group
                            v-for="c in initiatedC"
                            :key="c.id"
                          >
                            <b-list-group-item
                              class="flex-column align-items-start"
                            >
                              <div class="d-flex w-100 justify-content-between">
                              <router-link
                                :to="{
                                  name: 'apps-candidates-view',
                                  params: { id: c.id, jobId: jobData.id },
                                  query: { position_name: jobData.position_name,
                                          client: jobData.client,
                                          attachments: jobData.attachments,
                                          recruiter: c.recruiter,
                                          locations: jobData.locations,
                                          sourcer: c.sourcer,
                                          am_name: jobData.account_mgr, job_id: jobData.job_id
                                  }
                                }"
                              >
                                <h5 class="mb-0"
                                >
                                  <u>{{ c.full_name }} </u>
                                </h5>
                              </router-link>  
                              </div>
                              <b-card-text class="mb-0" style="font-size: 0.9rem !important">
                                
                                {{ c.contact_no }}
                              </b-card-text>
                              Status
                              </a>
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                    </app-collapse-item>
                  </app-collapse> -->
                
                
                
                <div>
                  <div class="demo-inline-spacing">
                    <h2 >Candidates Pool</h2> 
                    <div class="text-right">
                      <span > Filter by: </span>
                      <span><b-badge pill class="text-capitalize btn-outline-info mr-2" id="badge-all"
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              @click="filterCandidate('all')"> All Candidates </b-badge>
                      </span>
                      <span><b-badge pill class="text-capitalize btn-outline-info " id="badge-my"
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              @click="filterCandidate('my')"> Added By Me </b-badge>
                      </span>
                    </div>
                  </div>
                  <b-card no-body class="mb-0" lazy>
                    <div class="m-2">
                    

                      <!-- Table Top -->
                      <b-row>
                        <!-- Per Page -->
                        <b-col
                          cols="12"
                          md="6"
                          class="
                            d-flex
                            align-items-center
                            justify-content-start
                            mb-1 mb-md-0
                          "
                        >
                          <label>Show</label>
                          <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            @change="onPerPageChange"
                            class="per-page-selector d-inline-block"
                          />
                          <label> of <b>{{ fetchCandidates.length }}</b> entries</label>

                          <!-- <label style="margin-left: 50px">Goto Page</label> <v-select
                            v-model="currentPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="numberOfPages"
                            :clearable="false"
                            @change="onPageChange"
                            class="per-page-selector d-inline-block mx-50"
                          /> -->
                        </b-col>

                        <!-- Search -->
                        <b-col cols="12" md="6">
                          <div
                            class="d-flex align-items-center justify-content-end"
                          >
                            <b-form-input
                              v-model="filter"
                              class="d-inline-block mr-1"
                              placeholder="Search..."
                              trim
                            />
                          </div>
                        </b-col>
                      </b-row>
                    </div>

                    <b-table
                      ref="refCandidateListTable"
                      class="position-relative"
                      :items="fetchCandidates"
                      responsive
                      :fields="tableColumns"
                      :per-page="perPage"
                      :current-page="currentPage"
                      primary-key="id"
                      :sort-by.sync="sortBy"
                      show-empty
                      :filter="filter"
                      empty-text="No matching records found"
                      :sort-desc.sync="isSortDirDesc"
                      
                    >
                      <!-- Column: Candidate -->
                      <template #cell(id)="data">
                        <b-media vertical-align="center">
                          <b-link
                            :to="{
                              name: 'apps-candidates-view',
                              params: { id: data.item.id, jobId: jobData.id },
                              query: { position_name: jobData.position_name,
                                         client: jobData.client,
                                         attachments: jobData.attachments,
                                         recruiter: data.item.recruiter,
                                         locations: jobData.locations,
                                         sourcer: JSON.parse(data.item.addedBy)[0].title,
                                         am_name: jobData.account_mgr, job_id: jobData.job_id,
                                         page: currentPage,
                                         position_type: jobData.type,
                                         vertical: jobData.vertical,
                                         category: jobData.category,
                                         payroll: jobData.payroll
                                }
                            }"
                            class="font-weight-bold d-block text-nowrap text-capitalize"
                          >
                            {{ data.item.full_name }} <br />
                            {{ data.item.candidate_id }}
                            
                          </b-link>
                          <span class="text-small" style="color:#a7a6aa; font-size: 12px">
                            Added on {{ data.item.createdAt }}</span>
                        </b-media>
                      </template>

                      <!-- Column: Contact -->
                      <template #cell(email)="data">
                        <div class="text-nowrap">
                          <span class="align-text-top ">{{
                            data.item.email
                          }}</span
                          ><br />
                          <span class="align-text-top ">{{
                            data.item.contact_no
                          }}</span>
                        </div>
                      </template>

                      <!-- Column: Location -->
                      <template #cell(curr_location)="data">
                        <div class="text-nowrap">
                          {{ data.item.curr_location }}
                        </div>
                      </template>
                      <!-- Column: Location -->
                      <template #cell(source)="data">
                        <div class="text-nowrap">
                          <span class="align-text-top text-capitalize">{{
                            data.item.sourcer_name
                          }}</span
                          ><span class="align-text-top text-capitalize" v-if="data.item.sourcer_name !== data.item.rec_name"><br />{{
                            data.item.rec_name
                          }}(R)</span
                          ><br />
                          <span class="align-text-top text-capitalize">{{
                            data.item.source
                          }}</span>
                        </div>
                      </template>
                      <!-- Column: Status -->
                      <template #cell(status)="data">
                       <span class="demo-inline-spacing">
                        <b-badge pill class="text-capitalize" :id="'badgeshow_' + data.item.id"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.bottom="data.item.candidateStatus === 'Not Interested' ? 'Reason: ' + data.item.reason + ' Comment: ' + data.item.comment : data.item.candidateStatus === 'Not Relevant' || data.item.candidateStatus === 'Call Back' ? ' Comment: ' + data.item.comment : ''"
                        :class="resolveCandidateStatusVariant(data.item.candidateStatus)">
                        
                          {{ data.item.candidateStatus }}<br>
                          
                        </b-badge>
                          <b-badge pill v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-if="data.item.extra_details && data.item.extra_details.test_status"> {{ data.item.extra_details.test_status }} | {{ data.item.extra_details.test_score}}</b-badge>
                          <!-- <b-link
                            href="#"
                            class="ml-1"
                            v-b-modal="'modal-extra-details-' + data.item.id"
                          >
                            <feather-icon 
                              icon="InfoIcon"
                              size="14"
                              class="text-primary"
                              v-b-tooltip.hover.bottom="data.item.extra_details"
                            />
                          </b-link> -->

                        
                          
                          <!-- <span :id="'edit_' + data.item.id" class="py-1" v-if="data.item.candidateStatus !== 'Uploaded' && data.item.candidateStatus !== 'UploadedBySourcer' && data.item.candidateStatus !== 'UploadedByRecruiter' && data.item.candidateStatus !== 'AssignedByMassMail' && data.item.candidateStatus !== 'Initiated' && data.item.candidateStatus !== 'Not Interested' && data.item.candidateStatus !== 'Not Answered' && data.item.candidateStatus !== 'Not Relevant' && data.item.candidateStatus !== 'Not Reachable' && data.item.candidateStatus !== 'Call Back' && data.item.candidateStatus !== 'Wrong Number'"
                          @click="updateClick(data.item, jobData.id, data.item.candidateStatus )">
                            <feather-icon icon="EditIcon" variant="outline-primary" />
                          </span> -->
                         
                      
                       </span>
                        
                      </template>

                      <!-- Column: Actions -->
                      <template #cell(actions)="data">
                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                          v-if="data.item.candidateStatus !== 'UploadedBySourcer' && data.item.candidateStatus !== 'UploadedByRecruiter' && data.item.candidateStatus !== 'AssignedByMassMail'"
                        >
                          <template #button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>
                          

                          <b-dropdown-item 
                            :to="{
                              name: 'apps-candidates-edit',
                              params: { id: data.item.id, jobId: jobData.id },
                            }"
                          >
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                          </b-dropdown-item>
                          <!-- <b-dropdown-item v-if="candidateStatus === 'UploadedBySourcer' && candidateStatus === 'Initiated' && candidateStatus === 'UploadedByRecruiter' && candidateStatus === 'AssignedByATSSourcer' && candidateStatus === 'AssignedByMassMail' && candidateStatus === 'Not Interested' && candidateStatus === 'Not Relevant' && candidateStatus === 'Not Answered' && candidateStatus === 'Not Reachable' && candidateStatus === 'Not Answered' && candidateStatus === 'Call Back' && candidateStatus === 'Wrong Number'"
                            @click="shareJD(jobData.attachments)"
                          >
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Share JD</span>
                          </b-dropdown-item> -->
                          <!-- <b-dropdown-item
                            @click="showRemoveCandidateModal(data.item.id, jobData.id)"
                          >
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Remove From this Job</span>
                          </b-dropdown-item> -->
                          <!--<b-dropdown-item @click="showCModal(data.item.id)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Update Status</span>
                          </b-dropdown-item>-->
                        </b-dropdown>
                      </template>
                    </b-table>
                    
                    <b-col
                    cols="12"
                  >
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="center"
                      size="sm"
                      class="my-0 mb-2"
                      @change="onPageChange"
                    />
                  </b-col>
                    
                  </b-card>
                  
                </div>  
              </b-tab>

              <b-tab title="Job Description" lazy id="job-description">
                <b-row>
                  <b-col cols="12">
                    <job-description-card
                      :job-description="jobData.job_description"
                      :notes="notes"
                      :file="jobData.attachments"
                    />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Assign Candidates" lazy id="assign-candidates" v-if="$can('read','candidates')">
                <b-row>
                  <b-col cols="12">
                    <candidate-list :job-id="jobData.id" :position-name="jobData.position_name" :client="jobData.client" :attachments="jobData.attachments" :locations="jobData.locations" :acc-mgr="jobData.account_mgr" :all="alltrue" @refreshParent="updateParent"/>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Activities" lazy id="activities" v-if="$can('read','job_activity')">
                <b-row>
                  <b-col cols="12">
                    <jobs-view-job-timeline-card :job-timeline="timeline" />
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </div>
          <b-modal
            id="modal-update-status"
            ref="modal-update-status"
            title="Update Status"
            ok-title="submit"
            cancel-variant="outline-secondary"
            @ok="updateStatus"
          >
            <b-form>
              <b-form-group label="Choose the Status" label-for="vue-select">
                <li
                  style="display: inline-flex; margin: 5px; padding: 2px"
                  v-for="status in statusOption"
                  :key="status.text"
                >
                  <b-badge
                    style="color: #5E5873 !important"
                    variant="light"
                    :id="status.text"
                    @click="selectStatus(status.text)"
                    >{{ status.value }}</b-badge
                  >
                </li>
              </b-form-group>
            </b-form>
          </b-modal>
          
          <b-modal
            id="modal-add-notes"
            ref="modal-add-notes"
            title="Add Notes"
            ok-title="submit"
            cancel-variant="outline-secondary"
            @ok="updateNotes"
          >
            <b-form>
              <b-form-group label="Add Notes" label-for="vue-select">
                <b-form-textarea
                  id="textarea-default"
                  v-model="note"
                  placeholder="Add Notes"
                  rows="3"
                />
              </b-form-group>
            </b-form>
          </b-modal>
          <b-modal
            id="modal-remove-candidate"
            ref="modal-remove-candidate"
            title="Reason to remove Candidate"
            ok-title="submit"
            cancel-variant="outline-secondary"
            @ok="removeCandidateFromJob"
          >
            <b-form>
              <b-form-group label="Reason to remove Candidate" label-for="vue-select">
                <b-form-textarea
                  id="textarea-default"
                  v-model="removeCandidateReason"
                  placeholder="Reason"
                  rows="3"
                />
                <small v-if="!removeCandidateReason" class="text-danger"> {{ removeCandError }}</small>
              </b-form-group>
            </b-form>
          </b-modal>
          <!-- <b-modal
        id="modal-cupdate-status"
        ref="modal-cupdate-status"
        title="Update Candidate Status"
        ok-title="submit"
        cancel-variant="outline-secondary"
        @ok="updateCandidateStatus"
      >
        <b-form>
          
          <b-form-group label="Choose the Status" label-for="vue-select">
            <li 
              style="display: inline-flex; margin: 5px; padding: 2px"
              v-for="status in cStatusOption"
              :key="status.text"
            >
              <b-badge
                style="color: black !important"
                variant="light"
                :id="status.text"
                @click="selectCStatus(status.text)"
                >{{ status.value }}</b-badge
              >
            </li>
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'Shortlisted'"
            label="Selection Date *"
            label-for="selectedDate"
          >
            <flat-pickr
              class="form-control"
              id="selDate" 
              v-model="selectionDate" 
              placeholder="YYYY-MM-DD"
              :config="flatpickrConfig"
            />
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'Offered'"
            label="Offered Date *"
            label-for="offeredDate"
          >
            <flat-pickr
              class="form-control"
              id="offerDate" 
              v-model="offeredDate" 
              placeholder="YYYY-MM-DD"
              :config="flatpickrConfig"
            />
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'Offered'"
            label="Joining Date"
            label-for="joiningDate"
          >     
            <flat-pickr
              class="form-control"
              id="joinDate" 
              v-model="joiningDate" 
              placeholder="YYYY-MM-DD"
            />
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'Offered'"
            label="Offered CTC"
            label-for="offeredCTC"
          >     
            <b-form-input
              class="form-control"
              id="offeredCTC" 
              v-model="offeredCTC" 
              placeholder="Enter CTC in Rupees(complete amount like 10000.00)"
              type="number"
              step=".01"
            />
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'Joined'"
            label="Joining Date *"
            label-for="joiningDate"
          >     
            <flat-pickr
              class="form-control"
              id="joinDate" 
              v-model="joiningDate" 
              placeholder="YYYY-MM-DD"
              
            />
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'Early Attrition'"
            label="Attrition Date *"
            label-for="attritionDate"
          >     
            <flat-pickr
              class="form-control"
              id="attritionDate" 
              v-model="attritionDate" 
              placeholder="YYYY-MM-DD"
              
            />
          </b-form-group>
          <b-form-group v-if="statusCSelected === 'NotOffered'"
            label="Drop Reason *"
            label-for="drop-reason"
          >
            <v-select
                      v-model="dropReason"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="notOfferedOptions"
                      ref="drop-reason"
                      :reduce="(option) => option.title"
                    />
                  
          </b-form-group>
          
          <b-form-group v-if="statusCSelected === 'OfferDrop'"
            label="Drop Reason *"
            label-for="drop-reason"
          >
            <v-select
                      v-model="dropReason"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="offerDropOptions"
                      ref="drop-reason"
                      :reduce="(option) => option.title"
                      taggable
                      push-tags
                    />
          </b-form-group> 
          <small v-if="!dropReason && (statusCSelected === 'NotOffered' || statusCSelected === 'OfferDrop')" class="text-danger"> Drop Reason is Mandatory !!</small>
          <small v-if="!statusCSelected" class="text-danger"> {{ error_status }}</small>
          
        </b-form>
      </b-modal> -->
        </b-col>
        <b-col cols="12" xl="12" />
      </b-row>
    </template>
    <!-- Modal to check Candidate Exist in Database Before opening in Naukri -->
    <template>
      
      <b-modal
            id="modal-check-exist-in-db"
            ref="modal-check-exist-in-db"
            title="Check Candidate Exist"
            ok-title="Assign"
            size="xl"
            cancel-variant="outline-secondary"
            @ok="assignJob">
        <b-row>

          <b-col md="12">    
            
            <b-form @submit.prevent>
              <span class="text-danger text-center"> *** NOTE: All Fields are Mandatory !!!</span><br>
              <b-row>
                <b-col md="6">
                    <b-form-group
                    label="First Name"
                    label-for="first-name"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="First Name"
                            rules="required"
                        >
                            <b-form-input
                                id="first-name"
                                v-model="canExist.firstName"
                                :state="errors.length > 0 ? false:null"
                                placeholder="First Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>    
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                    label="Last Name"
                    label-for="last-name"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="Last Name"
                            rules="required"
                        >
                            <b-form-input
                                id="last-name"
                                v-model="canExist.lastName"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Last Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>    
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                    label="Skill"
                    label-for="skill"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="Skill"
                            rules="required"
                        >
                            <b-form-input
                                id="skill"
                                v-model="canExist.skill"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Candidate skill"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>    
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                    label="Current Company"
                    label-for="current-company"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="Current Company"
                            rules="required"
                        >
                            <b-form-input
                                id="current-company"
                                v-model="canExist.company"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Candidate Current Company"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>    
                    </b-form-group>
                </b-col>
                <b-col md="12" class="text-center">
                  
                  <b-button variant="outline-primary" @click="checkCandidateExistGetData">Search</b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
          <b-col md="12" class="mt-2" v-if="canExistCandidatesList">
            <span v-if="loading"><b-spinner variant="primary" label="Text Centered"/> Fetching candidates ...</span>
            <b-table
              class="position-relative"
              :items="canExistCandidatesList"
              :fields="canExistCandidatesListFields"
              responsive
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
              select-mode="single"
              selectable
              @row-selected="onRowSelected"
            >
            <!-- <b-table
              class="position-relative"
              :items="canExistCandidatesList"
              :fields="canExistCandidatesListFields"
              responsive
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
              select-mode="single"
              selectable
              @row-selected="onRowSelected"
            > -->
              <template #cell(id)="data">
                <router-link :to="'/apps/candidates/view/' + data.item.id + '/null'" target="_blank" v-if="$can('read','candidates')">
                  {{ data.item.candidate_id}}
                </router-link>
              </template>
              <!-- { key: "id", sortable: true, Label: "ID" },
          { key: "first_name", sortable: true, Label: "Name" },
          { key: "email", sortable: true, Label: "Contact" },
          { key: "curr_company", sortable: true, Label: "Company" },
          { key: "source", sortable: true, Label: "source" },
          { key: "exp", sortable: true, Label: "EXPERIENCE/CTC" },
          { key: "candidateStatus", sortable: true, Label: "STATUS" },
          { key: "job_ids", sortable: true, Label: "JOBS" }, -->
              <template #cell(first_name)="data">
                  {{ data.item.first_name  + ' ' +  data.item.last_name }}
              </template>
              <template #cell(email)="data">
                  <p>{{ data.item.email }} </p>
                  <p>{{ data.item.contact_no }} </p>
              </template>
              <template #cell(curr_company)="data">
                  <p>{{ data.item.curr_company }} </p>
                  <p>{{ data.item.curr_postion }} </p>
              </template>
              <template #cell(source)="data">
                  <p>{{ data.item.source }} </p>
                  <p>{{ data.item.gender }} </p>
              </template>
              <template #cell(exp)="data">
                  {{ data.item.exp }} Years / ₹ {{ data.item.ctc ? data.item.ctc : 0 }} <span v-if="!blueCollar">Lacs</span>
                  
              </template>
              <template #cell(candidateStatus)="data">
                  {{ data.item.candidateStatus }} 
              </template>
              <template #cell(job_ids)="data">
                <!-- {{ data.item.job_ids.split(',') }} -->
                <span v-if="data.item.job_ids">
                  <p v-for="(job,index) in data.item.job_ids.split(',')" :key="index" >
                    <router-link :to="'/apps/view-job/' + job" target="_blank">
                      View Job {{ index + 1 }}
                    </router-link>
                  </p>
                </span>
              </template>

            </b-table>
          </b-col>
        </b-row>
      </b-modal>
    </template>
    <template>
        <b-modal
            id="cv-preview-modal"
            ref="cv-preview-modal"
            ok-only
            ok-title="Accept"
            centered
            size="xl"
            title="CV Preview"
        >
            <div>
                <div v-if="pdf">
                    <iframe :src="blink" frameborder="0" height="1200px" width="100%"></iframe>
                </div>
                <div v-if="docx || doc">
                  <a class="text-center font-weight-bolder" :href="mlink" target="_blank" v-if="docx || doc"> Preview CV</a>
                    <iframe :src="mlink" frameborder="0" height="1200px" width="100%"></iframe>
                </div>
                <div v-if="rtf">
                    <iframe :src="glink" frameborder="0" height="1200px" width="100%"></iframe>
                </div>
                <div v-if="otherDoc">
                    <iframe :src="mlink" frameborder="0" height="1200px" width="100%"></iframe>
                </div>
            </div>
        </b-modal>
    </template>
    <template v-if="!jobData">
      <b-row>
        <b-col cols="12" xl="12">
          <!--<jobs-view-job-info-card :job-data="jobData" :notes="notes" :file="jobData.attachments" :job-description="jobData.job_description"/>-->
          <div>
            You are not authorized to View this Job Details
          </div>
        </b-col>
      </b-row>    
    </template>

    <!-- Update the modal implementation -->
    <b-modal
      id="modal-custom-date"
      ref="modal-custom-date"
      title="Select Date Range"
      ok-title="Download"
      cancel-variant="outline-secondary"
      @ok="handleCustomDateDownload"
    >
      <b-form>
        <b-form-group
          label="Start Date"
          label-for="start-date"
        >
          <flat-pickr
            id="start-date"
            v-model="customDateRange.startDate"
            class="form-control"
            placeholder="YYYY-MM-DD"
            :config="{ enableTime: false, dateFormat: 'Y-m-d' }"
          />
        </b-form-group>

        <b-form-group
          label="End Date"
          label-for="end-date"
        >
          <flat-pickr
            id="end-date"
            v-model="customDateRange.endDate"
            class="form-control"
            placeholder="YYYY-MM-DD"
            :config="{ enableTime: false, dateFormat: 'Y-m-d' }"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <div v-if="isDownloading || isCustomDownloading" class="loading-overlay">
      <b-spinner variant="primary" label="Loading..."></b-spinner>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BButton,
  BAvatarGroup,
  BAvatar,
  BRow,
  BCol,
  BTabs,
  BTab,
  VBTooltip,
  BCardBody,
  BCardText,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormTextarea,
  BCardHeader,
  BSidebar,
  BBadge,
  BListGroup,
  BListGroupItem,
  BFormInput,
  BTable,
  BPagination,
  BMedia,
  BLink,
  BSpinner //BButton, BSidebar, VBToggle
} from "bootstrap-vue";
//import {BButton, BSidebar, VBToggle} from 'bootstrap-vue'
//import Ripple from 'vue-ripple-directive'
//import JobsViewJobInfoCard from './JobsViewJobInfoCard.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CandidateList from "@/views/apps/candidate/candidates-list/CandidateList.vue";
import JobDescriptionCard from "./JobDescriptionCard.vue";
import JobsViewJobTimelineCard from "./JobsViewJobTimelineCard.vue";
import JobService from "@/services/job-service";
import NoteService from "@/services/note-service";
import flatPickr from 'vue-flatpickr-component'
//import CandidateService from "@/services/candidate-service";
import CandidateListAddNew from "@/views/apps/candidate/candidates-list/CandidateListAddNew.vue";
//import CandidateListAddNewOld from "@/views/apps/candidate/candidates-list/CandidateListAddNew_OLD.vue";
import moment from "moment";
import jobTimelineService from '@/services/job-timeline'
import candidateService from "@/services/candidate-service";
import emailTemplates from "@/services/email-templates";
import mailService from "@/services/mail-service"
import UserService from "@/services/user-service"
import elasticSearchService from "@/services/elastic-search-service"
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { required,double } from '@validations'
import jobService from '../../../../services/job-service';

import Excel from 'exceljs';
import { saveAs } from 'file-saver';
// import Vue from 'vue';
// // Import Vuex store if it's not in the same file
// import store from '@/store';
// // import { abilitiesPlugin } from '@casl/vue';
// // Vue.use(abilitiesPlugin, store.state.ability);



export default {
  components: {
    BRow,
    BCol,
    //BAlert,
    //JobsViewJobInfoCard,
    BCard,
    BButton,
    BAvatarGroup,
    BAvatar,
    BTabs,
    BTab,
    JobDescriptionCard,
    JobsViewJobTimelineCard,
    CandidateList,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BCardHeader,
    BSidebar,
    BBadge,
    BListGroup,
    BListGroupItem,
    BCardText,
    BTable,
    BPagination,
    CandidateListAddNew,
    //CandidateListAddNewOld,
    BMedia,
    BLink,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    // abilitiesPlugin
    
    //BButton,
    //BSidebar,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      id: "",
      jobData: {},
      notesData: [],
      jobDescription: "",
      file: "",
      pageLength: 3,
      dir: false,
      statusSelected: "",
      statusCSelected: "",
      job_id: "",
      statusOption: [
        { value: "Active", text: "Active" },
        { value: "On Hold", text: "OnHold" },
        { value: "Draft", text: "Draft" },
        { value: "Closed", text: "Closed" },
        { value: "Cancelled", text: "Cancelled" },
        { value: "Target Date Breached", text: "TargetDateBreached" },
      ],
      
      cStatusOption: [
        // { value: "Screen Reject", title: "ScreenReject" },
        // { value: "Screen Select", title: "ScreenSelect" },
        // { value: "Duplicate", title: "Duplicate" },
        // { value: "Inprocess", title: "Inprocess" },
        // { value: "OnHold", title: "OnHold" },
        // { value: "Shortlisted", title: "Shortlisted" },
        // { value: "Offered", title: "Offered" },
        // { value: "Not Offered", title: "NotOffered" },
        // { value: "Joined", title: "Joined" },
        // { value: "Offer Drop", title: "OfferDrop" },
        // { value: "Rejected", title: "Rejected" },
      ],
      fetchCandidates: [],
      fetchCandidatesBKP: [],
      tableColumns: [
        { key: "id", sortable: true, Label: "Title" },
        //{ key: 'first_name', sortable: true },
        { key: "email", sortable: true, label: "Contact" },
        //{ key: 'contact_no', label: 'Contact', sortable: true },
        {
          key: "curr_location",
          label: "Location",
          formatter: true,
          sortable: true,
        },
        {
          key: "source",
          label: "Sourced",
          formatter: true,
          sortable: true,
        },
        { key: "status", sortable: true },
        { key: "actions" },
      ],
      note: "",
      totalCandidates: 0,
      initiatedC: {},
      approvedC: {},
      pageNumber: null,
      inprocessC: {},
      shortlistedC: {},
      offeredC: {},
      joinedC: {},
      screenSelectC: {},
      onholdC: {},
      reviewrejectC: {},
      screenrejectC: {},
      duplicateC: {},
      notofferedC: {},
      offerdropC: {},
      rejectedC: {},
      notrelevantC: {},
      notinterestedC: {},

      rows: [],
      searchTerm: "",
      avatarText,
      selected: "Active",
      option: [
        "Active",
        "OnHold",
        "Draft",
        "Closed",
        "Cancelled",
        "TargetDateBreached",
      ],
      alltrue: "true",
      allfalse: "false",
      perPage: parseInt(this.$route.query.size) || 25,
      totalRows: 0,
      totalCandidates: 0,
      currentPage: parseInt(this.$route.query.page) || 1,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: "",
      sortBy: "id",
      isSortDirDesc: true,
      sortDesc: true,
        sortDirection: 'desc',
      from: 0,
      to: 0,
      of: 0,
      isAddNewCandidateSidebarActive: false,
      filter: "",
      noticePeriod: [{title: "Immediate", value: 0}, {title: "0-15", value: 15}, {title: "15-30", value: 30},{title: "30-45", value: 45},{title: "45-60", value: 60}, {title: "60-90", value: 90}],
      removeCandidateReason: '',
      can_id: '',
      removeCandError: '',
      canLoadingDone: false,
      timeline: '',
      notes: '',
      isRecruiter: '',
      isSourcer: '',
      isAccountManager: '',
      isAdminMaster: '',
      // updateClicked: false,
      allUsers: '',
      joiningDate: '',
      offeredDate: '',
      selectionDate: '',
      attritionDate: '',
      isValid: true,
      candidate: {},
      candidateId: '',
      jobId: '',
      prevStatus: '',
      error_status: '',
      offeredCTC: '',
      dropReason: '',
      notOfferedOptions: [
        {title: 'CTC Issue'},
        {title: 'Notice Period Issue'},
        {title: 'Location Issue'},
        {title: 'Designation Issue'},
        {title: 'Position Closed'},
        {title: 'Position On-Hold'}
      ],
      offerDropOptions: [
        {title: 'Got Another Offer'},
        {title: 'Retained By Current Organization'},
        {title: 'CTC Issue'},
        {title: 'Notice Period Issue'},
        {title: 'Location Issue'},
        {title: 'Designation Issue'},
        {title: 'BGV/Documentation Issue'},
      ],
      flatpickrConfig: {
        maxDate: "today"
      },
      numberOfPages : [],
      newPage: true,
      canExist: {
        firstName: null,
        lastName: null,
        skill: null,
        company: null
      },
      canExistCandidatesList: null,
      canExistCandidatesListFields : [
        { key: "id", sortable: true, label: "ID" },
        { key: "first_name", sortable: true, label: "Name" },
        { key: "email", sortable: true, label: "Contact" },
        { key: "skills", sortable: true, label: "Job Name" },
        { key: "curr_company", sortable: true, label: "Company" },
        { key: "source", sortable: true, label: "source" },
        { key: "exp", sortable: true, label: "EXPERIENCE/CTC" },
        { key: "candidateStatus", sortable: true, label: "STATUS" },
        { key: "job_ids", sortable: true, label: "JOBS" },
      ],
      //variables for doc preview
      blink: null,
      glink: null,
      pdf: false,
      docx: false,
      rtf: false,
      doc: false,
      otherDoc: false,
      mlink: null,
      dlink: null,
      selectedCandidates: [],
      empId: null,
      filterType: null,
      loading: false,
      blueCollar: false,
      currentTime: null,
      // searchFilters: {
      //   page : 1
      // }
      //jData: this.jobData
      customDateRange: {
        startDate: '',
        endDate: ''
      },
      isDownloading: false,
      isCustomDownloading: false
    };
  },
  watch: {
    // '$route'(to, from) {
    //   // console.log('to: ', to)
    //   // console.log('from: ', from)
    //   if (to.query.page !== from.query.page) {
    //     this.newPage = false
    //   }
    // }
    // currentPage: {
    //   this.searchFilters.page = currentPage
    // },
    // When currentPage changes, update the route if different from current route
    // currentPage: {
    //     handler(newFilters) {
    //       // Convert query parameters and new filters to strings for easy comparison
    //       const currentQuery = JSON.stringify(this.$route.query);
    //       const newQuery = JSON.stringify(newFilters);

    //       // Only navigate if the queries are different
    //       if (currentQuery !== newQuery) {
    //         this.$router.push({ query: { page: currentPage} }).catch(err => {
    //           if (err.name !== 'NavigationDuplicated') {
    //             throw err;
    //           }
    //           // Ignore NavigationDuplicated error, but rethrow all other errors
    //         });
    //       }
    //     },
    //     deep: true,
    //   },
    //   $route(to) {
    //     this.currentPage = { ...this.currentPage, ...to.query };
    //   },
  },
  created() {
    this.id = this.$route.params.id;
    console.log('abilities can', this.$can('update', 'Job'))
    
    localStorage.setItem('pageSize', this.perPage);
    // if(this.$route.query) { 
    //   this.currentPage = this.$route.query.page ? this.$route.query.page : ''
    // } else {
    //   this.$router.push({ query: { page: this.currentPage }});
    // }
    // console.log('route queries', this.$route)
    // if(!this.$route.query.page) {
    //   console.log('in no route query')
    //   this.$router.push({ query: { page: this.currentPage, size: this.perPage }});
    //   //this.searchFilters.page = this.currentPage
    // }
    // if(this.$route.query) {
    //   this.$router.push({ query: { page: this.$route.query.page, size: this.perPage }});
    //   //this.searchFilters.page = this.$route.query.page
    //   this.currentPage = this.$route.query.page
    //   this.perPage = this.$route.query.size
    //   //this.searchFilters.page = this.$route.query.page
    // } 
    const userD = JSON.parse(localStorage.getItem('userData'))
    this.empId = userD.emp_id
    
    //this.setCurrentPageFromRoute();
    //currentPage: parseInt(this.$route.query.page) || 1,
    ////console.log(this.id);

    this.getJobData();
    // const localItemsCount = this.fetchCandidates
    //   ? this.fetchCandidates.length
    //   : 0;
    // this.from =
    //   this.perPage.value * (this.currentPage.value - 1) +
    //   (localItemsCount ? 1 : 0);
    // this.to =
    //   this.perPage.value * (this.currentPage.value - 1) + localItemsCount;
    // this.of = this.fetchCandidates.length;

    // UserService.getAllUsersDd().then(res => {
    //   this.allUsers = res.data
    //   //console.log('recruiters',this.allUsers)
    // })
    UserService.getAllUsersDdActiveInactive().then(res => {
      this.allUsers = res.data
      //console.log('recruiters',this.allUsers)
    })
  },
  computed: {
    // fetchCandidates() {
    //   return this.filter
    //     ? this.fetchCandidates.filter(
    //         (item) =>
    //           item.first_name.includes(this.filter) ||
    //           item.last_name.includes(this.filter) ||
    //           item.email.includes(this.filter) ||
    //           item.contact_no.includes(this.filter) ||
    //           item.curr_location.includes(this.filter) ||
    //           item.source.includes(this.filter) ||
    //           item.status.includes(this.filter) ||
    //           item.candidate_id.includes(this.filter)
    //           //item.curr_company.includes(this.filter)
    //       )
    //     : this.fetchCandidates;
    // },
    // ifPermitted() {
    //   const userD = JSON.parse(localStorage.getItem('userData'))
    //   const role = userD.roles[0]
    //   let permit = false
    //   if(role === 'ACCOUNT-MANAGER' || role === 'ADMIN' || role === 'MASTER' || this.empId === 'SH-038'){
    //     permit = true
    //   } else {
    //     permit = false
    //   }
    //   return permit;
    // },
    // ifHRMIS() {
    //   const userD = JSON.parse(localStorage.getItem('userData'))
    //   const role = userD.roles[0]
    //   let permit = false
    //   if( role === 'HR' || role === 'MIS' ){
    //     permit = false
    //   } else {
    //     permit = true
    //   }
    //   return permit;
    // },
    updateShow() {

    }
  },
  methods: {

    isLoggedIn() {
      const expireIn = localStorage.getItem('expireIn')
      if (Math.floor(Date.now() / 1000 > expireIn)) {
          window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
          //console.log("called logout")
          localStorage.clear();
          this.$router.push({ name: "auth-login" });
      }
    },
    openAddJobSourcerPage() {
      this.isLoggedIn()
      
      this.$root.$emit('bv::toggle::collapse', 'sidebar-right-sourcer')
      // this.currentTime = new Date().getTime()
    },
    openAddJobPage() {
      this.isLoggedIn()
      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    
    getJobData() {

      JobService.getJobViewData(this.id).then(res => {
        console.log(res.data)
        if(res.status === "OK") {

          this.jobData = res.data.job[0]
          this.fetchCandidates = res.data.candidates
          this.totalRows = this.fetchCandidates.length
          this.fetchCandidatesBKP = this.fetchCandidates
          //this.searchFilters.page = this.$route.query.page
          this.currentPage = this.$route.query.page || 1
          if(this.jobData.type === 'Contractual' && this.jobData.category === 'Blue Collar' && this.jobData.payroll === 'Sattva Payroll' && this.jobData.vertical === 'Infra Industry') {
            this.blueCollar = true
            // this.ctcLabel = 'Monthly Salary'
          }
          // const pages = this.totalRows/this.perPage
          // for (let i = 1; i <= pages; i++) {
          //   this.numberOfPages.push(i)

          // }
          // console.log('pages number: ', this.numberOfPages)
          // if(this.pageNumber !== 1) {

          // }

          //this.canCount = res.data.canStatusCount[0]
          this.timeline = res.data.timeline
          this.notes = res.data.notes
          const user = JSON.parse(localStorage.getItem('emp_id'))
          const userRole = JSON.parse(localStorage.getItem('userData')).roles[0]
          
          //const notice_period = this.noticePeriod.filter(item => item.value === this.jobData.)

          this.recruiters = this.jobData.recruiters;
          this.account_mgr = this.jobData.account_mgr;
          //console.log(this.jobData.sourcer)
          if(this.jobData.sourcer) {
            this.sourcer = this.jobData.sourcer ? this.jobData.sourcer : '';
            // this.isSourcer = this.jobData.sourcer.filter(item => item.emp_id = user)
          }
          // this.isRecruiter = this.jobData.recruiters.filter(item => item.emp_id = user)
          
          // this.isAccountManager = userRole === 'ACCOUNT-MANAGER' ? true : false
          // this.isAdminMaster = userRole === 'ADMIN' || userRole === 'MASTER' ? true : false
          //console.log(this.isRecruiter, this.isSourcer, this.isAccountManager, this.isAdminMaster)
          /*this.recruiters = JSON.parse(
            this.recruiters.replace(/(\w+:)|(\w+ :)/g, function (matchedStr) {
              return (
                '"' + matchedStr.substring(0, matchedStr.length - 1) + '":'
              );
            })
          );
          if (res.data.sourcer) {
            this.sourcer = JSON.parse(
              this.sourcer.replace(/(\w+:)|(\w+ :)/g, function (matchedStr) {
                return (
                  '"' + matchedStr.substring(0, matchedStr.length - 1) + '":'
                );
              })
            );
          }
          this.account_mgr = JSON.parse(
            this.account_mgr.replace(/(\w+:)|(\w+ :)/g, function (matchedStr) {
              return (
                '"' + matchedStr.substring(0, matchedStr.length - 1) + '":'
              );
            })
          );*/
          //console.log(this.approvedC, this.inprocessC, this.screenSelectC)
          // this.approvedC = this.fetchCandidates.filter(
          //     item => item.candidateStatus === "ProfileShared"
          // );
          // this.inprocessC = this.fetchCandidates.filter(
          //   item => item.candidateStatus === "Inprocess"
          // );
          // this.screenSelectC = this.fetchCandidates.filter(
          //   item => item.candidateStatus === "ScreenSelect"
          // );
          // this.duplicateC = this.fetchCandidates.filter(
          //   item => item.candidateStatus === "Duplicate"
          // );
          // this.onholdC = this.fetchCandidates.filter(
          //   item => item.candidateStatus === "OnHold"
          // );
          // this.shortlistedC = this.fetchCandidates.filter(
          //   item => item.candidateStatus === "Shortlisted"
          // );
          // this.offeredC = this.fetchCandidates.filter(
          //   item => item.candidateStatus === "Offered"
          // );
          // this.joinedC = this.fetchCandidates.filter(
          //   item => item.candidateStatus === "Joined"
          // );
          // this.initiatedC = this.fetchCandidates.filter(
          //   item => item.candidateStatus === "Initiated" || item.candidateStatus === 'UploadedBySourcer' || item.candidateStatus === 'UploadedByRecruiter' || item.candidateStatus === 'AssignedByMassMail'
          // );
          // this.reviewrejectC = this.fetchCandidates.filter(
          //   item => item.candidateStatus === "ReviewReject"
          // );
          // this.screenrejectC = this.fetchCandidates.filter(
          //   item => item.candidateStatus === "ScreenReject"
          // );
          // this.notofferedC = this.fetchCandidates.filter(
          //   item => item.candidateStatus === "NotOffered"
          // );
          // this.offerdropC = this.fetchCandidates.filter(
          //   item => item.candidateStatus === "OfferDrop"
          // );
          // this.rejectedC = this.fetchCandidates.filter(
          //   item => item.candidateStatus === "Rejected"
          // );
          // this.notrelevantC = this.fetchCandidates.filter(
          //   item => item.candidateStatus === "Not Relevant"
          // );
          // this.notinterestedC = this.fetchCandidates.filter(
          //   item => item.candidateStatus === "Not Interested"
          // );
          this.canLoadingDone = true
          

        }
        else if ((res.status === 401 ) || (res.status === 403 )) {
            window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
            localStorage.clear();
            this.$router.push({ name: "auth-login" });
        }
      })
      
    },
    showModal(jobid, status) {
      this.job_id = jobid;
      this.statusSelected = status;
      //console.log(this.statusSelected);
      this.$refs["modal-update-status"].show();
    },
    /*showCModal(id) {
      this.candidate_id = id;
      //this.statusCSelected = status
      //console.log(this.statusCSelected);

      this.$refs["modal-cupdate-status"].show();
    },*/
    showModalNotes(jobid) {
      this.job_id = jobid;
      //console.log(this.job_id);
      this.$refs["modal-add-notes"].show();
    },
    showRemoveCandidateModal(id, jobid) {
      this.can_id = id
      this.job_id = jobid
      this.$refs["modal-remove-candidate"].show();
    },
    selectStatus(status) {
      this.statusSelected = status;
      for (var i = 0; i < this.statusOption.length; i++) {
        if (this.statusOption[i].text === status) {
          document.getElementById(this.statusOption[i].text).classList.remove('badge-light')
          document.getElementById(this.statusOption[i].text).classList.add('badge-primary')
          var el = document.getElementById(status);
          el.style.color = 'white';
        } else {
          document.getElementById(this.statusOption[i].text).classList.remove('badge-primary')
          document.getElementById(this.statusOption[i].text).classList.add('badge-light')
          var el = document.getElementById(this.statusOption[i].text);
          el.style.color = 'black';
        }
      }
    },
    /*selectCStatus(status) {
      this.statusCSelected = status;
      //console.log(this.statusCSelected);
      document.getElementById(status).classList.remove("badge-light");
      document.getElementById(status).classList.add("badge-primary");
      var el = document.getElementById(status);
      el.style.color = "white";
    },*/
    updateStatus() {
      const userData = JSON.parse(localStorage.getItem("userData"));

      const user = `[{"title" : "${userData.first_name} ${userData.last_name}", "emp_id": "${userData.emp_id}"}]`;
      const username = `${userData.first_name} ${userData.last_name}`
      //console.log("user", user);
      const status = {
        status: this.statusSelected,
        updated_by: user,
      };
      JobService.updateJobStatus(this.job_id, status).then((res) => {
        //console.log(res.data);
        if (res.status === "OK") {
          const timelineData = {
            title: `${this.jobData.job_id} - Job Status Updated to ${this.statusSelected}`,
            description: `Previous Status : ${this.jobData.status}`,
            type: "info",
            createdBy: username,
            jobId: this.jobData.id,
            candidate_id: null
          }
          //console.log(timelineData);
          
          jobTimelineService.create(timelineData).then(res => {
            //console.log("timeline", res)
            
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Job Status Updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.getJobData();
        } else if ((res.status === 401 ) || (res.status === 403 )) {
            window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
            localStorage.clear();
            this.$router.push({ name: "auth-login" });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Job Status Update Failed",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    
    updateNotes() {
      const userData = JSON.parse(localStorage.getItem("userData"));

      const user = `[{"title" : "${userData.first_name} ${userData.last_name}", "emp_id": "${userData.emp_id}"}]`;
      const username = `${userData.first_name} ${userData.last_name}`
      const notes = {
        note_description: this.note,
        jobId: this.job_id,
        recruiter_id: user,
      };
      //console.log(notes);
      NoteService.addNote(notes).then((res) => {
        //console.log(res.data);
        if (res.status === "OK") {
          const timelineData = {
            title: `${this.jobData.job_id} - Note Added`,
            description: `Note: ${res.data.note_description}`,
            type: "info",
            createdBy: username,
            jobId: this.jobData.id,
            candidate_id: null
          }
          //console.log(timelineData);
          
          jobTimelineService.create(timelineData).then(res => {
            //console.log("timeline", res)
          })
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Job Notes Added",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.getJobData();
        } else if ((res.status === 401 ) || (res.status === 403 )) {
            window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
            localStorage.clear();
            this.$router.push({ name: "auth-login" });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Job Note Add Failed",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
      //this.notes=''
    },
    removeCandidateFromJob() {
      if(this.removeCandidateReason) {
        const userData = JSON.parse(localStorage.getItem("userData"));

        const user = `[{"title" : "${userData.first_name} ${userData.last_name}", "emp_id": "${userData.emp_id}"}]`;
        const username = `${userData.first_name} ${userData.last_name}`

        candidateService.removeCandidateFromJob(this.can_id, this.job_id).then(res => {
          if(res.status === "OK") {
            const timelineData = {
              title: `${this.jobData.job_id} - Candidate Removed from Job(${this.can_id})`,
              description: `Reason: ${this.removeCandidateReason}`,
              type: "danger",
              createdBy: username,
              jobId: this.jobData.id,
              candidate_id: this.can_id
            }
            this.addRemoveCandidateToTimeline(timelineData);
          } else if ((res.status === 401 ) || (res.status === 403 )) {
            window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
            localStorage.clear();
            this.$router.push({ name: "auth-login" });
          }
        })
      }
      else {
        this.removeCandError = 'Reason Cannot be Empty'
      }
    },
    addRemoveCandidateToTimeline(data) {
      jobTimelineService.create(data).then(res => {
            //console.log("timeline", res)
      })
      this.getJobData();
      this.$toast({
            component: ToastificationContent,
            props: {
              title: "Candidate Removed from this Job",
              icon: "Trashicon",
              variant: "success",
            },
          });
    },
    resolveCandidateStatusVariant(status) {
      ////console.log("called")
      //const resolveCandidateStatusVariant = status => {
        
      if (status === 'Uploaded') return 'badge-secondary'
      if (status === 'Initiated') return 'badge-warning'
      if (status === 'ReviewReject') return 'badge-danger'
      if (status === 'ProfileShared') return 'badge-info'
      if (status === 'ScreenReject') return 'badge-danger'
      if (status === 'ScreenSelect') return 'badge-info'
      if (status === 'Duplicate') return 'badge-danger'
      if (status === 'Inprocess') return 'badge-info'
      if (status === 'On-Hold') return 'badge-warning'
      if (status === 'Shortlisted') return 'badge-success'
      if (status === 'Offered') return 'badge-success'
      if (status === 'NotOffered') return 'badge-danger'
      if (status === 'Joined') return 'badge-success'
      if (status === 'OfferDrop') return 'badge-danger'
      if (status === 'Rejected') return 'badge-danger'
      if (status === 'Wrong Number') return 'badge-danger'
      if (status === 'Not Answered') return 'badge-warning'
      if (status === 'Not Reachable') return 'badge-warning'
      if (status === 'Call Back') return 'badge-warning'
      if (status === 'Not Interested') return 'badge-danger'
      if (status === 'Not Relevant') return 'badge-danger'
      if (status === 'Early Attrition') return 'badge-danger'
      if (status === 'UploadedBySourcer') return 'badge-warning'
      if (status === 'UploadedByRecruiter') return 'badge-warning'
      if (status === 'AssignedByMassMail') return 'badge-warning'
      if (status === 'AssignedByATSSourcer') return 'badge-warning'
      if (status === 'Confirmed') return 'badge-success'
      if (status === 'Document Submitted') return 'badge-success'
      if (status === 'Document Pending') return 'badge-warning'
      if (status === 'Drop') return 'badge-danger'
      if (status === 'Attrition') return 'badge-danger'
      return 'badge-secondary'
      
  
    },
    updateParent(i) {
      //console.log(i)
      this.getJobData()
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    // updateClick(candidate,jobid, status) {
    //    this.updateClicked = !this.updateClicked
    //    ////console.log('text1', text, text1)
    //    //console.log('CANDIDATE STATUS', candidate)
    //    this.prevStatus = status
    //    this.candidateId = candidate.id
    //    this.jobId = jobid
    //    this.candidate = candidate
    //    if(this.updateClicked) {
    //     this.computeStatusOption()
    //     this.$refs["modal-cupdate-status"].show();
    //    }  
    //     // document.getElementById('updatecan_' + id).style.display = "";
    //     // document.getElementById('badgeshow_' + id).style.display = "none";
    //     //document.getElementById('edit_' + id).style.display = "none";
    //   //  } else {
    //   //   this.$refs["modal-cupdate-status"].hide();
    //   //   // document.getElementById('updatecan_' + id).style.display = "none";
    //   //   // document.getElementById('badgeshow_' + id).style.display = "";
    //   //   //document.getElementById('edit_' + id).style.display = "block";
    //   //  }
       
       
    // },
    computeStatusOption() {
      //console.log('inside compute')
      if (this.prevStatus === 'Initiated') {
        this.cStatusOption = [
        { value: "Review Reject", text: "ReviewReject" },
        { value: "Profile Shared", text: "ProfileShared" }
      ]
      } else if (this.prevStatus === 'ProfileShared') {
        this.cStatusOption = [
        { value: "Screen Reject", text: "ScreenReject" },
        { value: "Screen Select", text: "ScreenSelect" },
        { value: "Duplicate", text: "Duplicate" },
        { value: "Inprocess", text: "Inprocess" },
        { value: "OnHold", text: "OnHold" },
      ]
      } else if (this.prevStatus === 'ScreenSelect') {
        this.cStatusOption = [
        { value: "OnHold", text: "OnHold" },
        { value: "Inprocess", text: "Inprocess" },
        { value: "Rejected", text: "Rejected" }
      ]
      } else if (this.prevStatus === 'Inprocess') {
        this.cStatusOption = [
        { value: "OnHold", text: "OnHold" },
        { value: "Shortlisted", text: "Shortlisted" },
        { value: "Rejected", text: "Rejected" },
        { value: "Duplicate", text: "Duplicate" },
      ]
      } else if (this.prevStatus === 'OnHold') {
        this.cStatusOption = [
        { value: "Inprocess", text: "Inprocess" },
        { value: "Shortlisted", text: "Shortlisted" },
        { value: "Rejected", text: "Rejected" },
        { value: "Duplicate", text: "Duplicate" },
      ]
      } else if (this.prevStatus === 'Shortlisted') {
        this.cStatusOption = [
        { value: "Offered", text: "Offered" },
        { value: "Not Offered", text: "NotOffered" },
        { value: "Duplicate", text: "Duplicate" },
      ]
      } else if (this.prevStatus === 'NotOffered') {
        this.cStatusOption = [
        { value: "Offered", text: "Offered" },
      ]
      } else if (this.prevStatus === 'Offered') {
        this.cStatusOption = [
        { value: "Joined", text: "Joined" },
        { value: "Offer Drop", text: "OfferDrop" },
        { value: "Duplicate", text: "Duplicate" },
      ]
      } else if (this.prevStatus === 'OfferDrop') {
        this.cStatusOption = [
        { value: "Joined", text: "Joined" },
      ]
      } else if (this.prevStatus === 'Joined') {
        this.cStatusOption = [
        { value: "OfferDrop", text: "OfferDrop" },
        { value: "Duplicate", text: "Duplicate" },
        { value: "Early Attrition", text: "Early Attrition" },
      ]
      }
    },
    selectCStatus(status) {
      this.statusCSelected = status;

      //this.clicked = true
      ////console.log(this.statusCSelected);
      
      for (var i = 0; i < this.cStatusOption.length; i++) {
        if (this.cStatusOption[i].text === status) {
          document.getElementById(this.cStatusOption[i].text).classList.remove('badge-light')
          document.getElementById(this.cStatusOption[i].text).classList.add('badge-primary')
          var el = document.getElementById(status);
          el.style.color = 'white';
        } else {
          document.getElementById(this.cStatusOption[i].text).classList.remove('badge-primary')
          document.getElementById(this.cStatusOption[i].text).classList.add('badge-light')
          var el = document.getElementById(this.cStatusOption[i].text);
          el.style.color = 'black';
        }
      }
    },
  onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.currentPage = 1
        this.totalRows = filteredItems.length
        console.log('Page: ', this.currentPage)
        // if(this.pageNumber !== 1) {
           this.currentPage = 1
        // } else{
          //this.currentPage = 1
        //}
        
  },
  filterCandidate(type) {
    this.filterType = type
    localStorage.setItem('filterType', this.filterType);
    const emp_id = JSON.parse(localStorage.getItem('userData')).emp_id
    
    //console.log('filter', emp_id, this.fetchCandidates.length, this.fetchCandidatesBKP.length)
    
    if (type === "my") {
      //console.log('filter me', emp_id, this.fetchCandidates.length, this.fetchCandidatesBKP.length)
      this.fetchCandidates = this.fetchCandidates.filter(item => item.recruiter === emp_id || item.sourcer === emp_id)
      // document.getElementById('badge-me').classList.remove('btn-outline-info')
      // //document.getElementById('badge-all').classList.add('btn-outline-info')
      // document.getElementById('badge-me').classList.add('badge-info')
      
    } else {
      // document.getElementById('badge-me').classList.remove('badge-info')
      // document.getElementById('badge-me').classList.add('btn-outline-info')
      // document.getElementById('badge-all').classList.remove('btn-outline-danger')
      // document.getElementById('badge-all').classList.add('badge-danger')
      //console.log('filter all', emp_id, this.fetchCandidates.length, this.fetchCandidatesBKP.length)
      this.fetchCandidates = this.fetchCandidatesBKP
    }
  },
  shareJD(jd_file) {

  },
  checkCandidateExistGetData() {
    this.loading = true
    const data = {
      firstName: this.canExist.firstName,
      lastName: this.canExist.lastName,
      skill: this.canExist.skill,
      company: this.canExist.company
    }
    elasticSearchService.checkCandidateExist(data).then(res => {
      console.log(res.data)
      if(res.status=== 'OK') {
        this.loading = false
        this.canExistCandidatesList = res.data.hits.map(item => item._source);
        console.log(this.canExistCandidatesList)
      }
    })
  },
  onRowSelected(items) {
    console.log('row selected:', items)
    this.previewCV(items[0].cv_path)
      const rowsSelected = items.map(({id}) => ({id}))
      console.log('row selected array:', rowsSelected)
      this.selectedCandidates = []
      for(var i in rowsSelected)
        this.selectedCandidates.push(rowsSelected[i].id);
        this.selectedCandidates = [...new Set(this.selectedCandidates)];
      console.log(this.selectedCandidates)
  },
  previewCV(cvFile) {
        
        const key = cvFile.replace("/","%2F")
        const temp = key.split(".");
        const extension = temp[temp.length - 1]; // Or parts.pop();

        if(extension === 'pdf') {
            this.pdf = true
          } else if (extension === 'docx'){
            this.docx = true
          }  else if (extension === 'rtf'){
            this.rtf = true
          } else if (extension === 'doc'){
            this.doc = true
          } else if (extension !== 'docx' || extension !== 'pdf' || extension !== 'rtf') {
            this.otherDoc = true
          } 
          
          JobService.getFile(key)
            .then(res => {
              //console.log(res)
              this.dlink = res.file;
              var encodedUrl = encodeURIComponent(res.file);
              this.glink = //'http://192.168.1.81:5000/download/' + response.data.cv_path
                "https://docs.google.com/viewer?url=" + encodedUrl + "&embedded=true";

              this.mlink = "https://view.officeapps.live.com/op/view.aspx?src=" + encodedUrl
              ////console.log(this.dlink)
              this.dlink = res.file;

          });
          if(this.pdf) {
            JobService.getFileBase64(key)
              .then(res => {
                ////console.log(res)
                const base64 = res.file;
                //var encodedUrl = encodeURIComponent(res.file);
                this.blink = "data:application/pdf;base64," + base64
                
            }); 
            
          }
          this.$refs["cv-preview-modal"].show();
  },
  numDaysBetween(d1, d2) {
        var diff = Math.abs(d1.getTime() - d2.getTime());
        return diff / (1000 * 60 * 60 * 24);
    },
  assignJob() {
        if(this.selectedCandidates && this.selectedCandidates.length > 0) {
            const jid = this.jobData.id
            console.log('in assign JOb slected candidates:', this.selectedCandidates)
            const userData = JSON.parse(localStorage.getItem("userData"));
            const user = `[{"title" : "${userData.first_name} ${userData.last_name}", "emp_id": "${userData.emp_id}"}]`;
            let canSource = 'ATS-CS'
            
            let sourcer = userData.emp_id
            let status = ''
            //let c = []
            const username = `${userData.first_name} ${userData.last_name}`
            // const userRole = JSON.parse(localStorage.getItem('userData')).roles[0]

            this.selectedCandidates.forEach(id => {
                console.log('emp_id', id)
                //c.push[id]
                const createdAt = this.canExistCandidatesList.filter(item => item.id === id)[0].createdAt
                const now = new Date();
                const diff = this.numDaysBetween(now, new Date(createdAt))
                const source = this.canExistCandidatesList.filter(item => item.id === id)[0].source
                console.log('diff',diff)
                
                // if(diff > 5 ) {
                //     canSource = 'ATS'
                //     console.log('source diff greater',canSource, diff, createdAt)
                // } else {
                //     canSource = source
                //     console.log('source diff less',canSource, diff, createdAt)
                // }
                if(diff > 30 ) {
                    sourcer = 'ATS'
                    console.log('source diff greater',canSource, diff, createdAt)
                } else {
                    sourcer = sourcer
                    console.log('source diff less',canSource, diff, createdAt)
                }

                let data = {
                    job_id: jid,
                    candidate_id: [id],
                    candidateStatus: 'AssignedByATSSourcer',
                    source: canSource,
                    addedBy: user,
                    updatedBy: user,
                    sourcer: sourcer,
                    //recruiter: userData.emp_id
                }
                const jt =  {
                        title: `Candidate Id - ${id} - Assigned to the job with Status ${data.candidateStatus}`,
                        description: `CS-Assign via Candidate Search Before Naukri View`, /// CS-Changed means candidate status changed
                        type: "success",
                        createdBy: username,
                        jobId: jid,
                        candidate_id: id,
                        candidateStatus: data.candidateStatus
                    }
                data.jt = jt
                
                console.log('assign to job ', data)
                JobService.assignCandidateToJob(data).then(res => {
                    
                    if(res.status === "OK") {
                    console.log('Assign to Job', id, jid, data, res)
                      this.getJobData()
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                        title: 'Candidate Added to Job, Please Fill in rest details to share Profile',
                        icon: 'EditIcon',
                        variant: 'success',
                        timeout: 10000,
                        },
                    })
                    
                    } else if (res.status === 410 || res.message === 'Candidate already exist in Current Job') {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                        title: 'Failed! Candidate already exist in Job !!!',
                        icon: 'EditIcon',
                        variant: 'danger',
                        timeout: 5000,
                        },
                    })
                    } else if ((res.status === 401 ) || (res.status === 403 )) {
            
                        window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
                        
                        localStorage.clear();
                        this.$router.push({ name: "auth-login" });
                    } else {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                        title: 'Candidate Add to Job Failed !!!',
                        icon: 'EditIcon',
                        variant: 'danger',
                        timeout: 5000,
                        },
                    })
                    }
                })
            })
        } else {
            alert('No Candidate Selected!!!')
        }
    }, 
    restorePagination() {
      const savedPage = localStorage.getItem('currentPage');
      
      console.log('saved page: ',savedPage, parseInt(savedPage))
      
      // Or const savedPage = sessionStorage.getItem('currentPage');
      if (savedPage) {
        this.currentPage = parseInt(savedPage);
      }
    },
    restorePageSize() {
      
      const perPage = localStorage.getItem('pageSize');
      //console.log('saved page: ',savedPage, parseInt(savedPage))
      this.perPage = perPage
      // Or const savedPage = sessionStorage.getItem('currentPage');
      if (perPage) {
        this.perPage = perPage
      }
    },
    onPageChange(pageNumber) {
      this.currentPage = pageNumber;
      console.log('current page: ',this.currentPage)
      localStorage.setItem('currentPage', this.currentPage);
      
      // Or sessionStorage.setItem('currentPage', pageNumber);
    },
    onPerPageChange(pageSize) {
      this.perPage = pageSize
      localStorage.setItem('pageSize', this.perPage);
    },
    async downloadJobtracker(id) {
      JobService.getJobTracker(id).then(res => {
        console.log(res)
        if(res.status === 200) {
          this.jobTracker = res.data.data
          console.log('tracker: ', this.jobTracker)
          const workbook = new Excel.Workbook();
          const worksheet = workbook.addWorksheet('Job Tracker');

          const headers = [
            { header: 'Candidate ID', key: 'Candidate ID', width: 15 },
            { header: 'Candidate Name', key: 'Candidate Name', width: 25 },
            { header: 'Email ID', key: 'Email ID', width: 25 },
            { header: 'Contact number', key: 'Contact number', width: 18 },
            { header: 'Current Company', key: 'Current Company', width: 20 },
            { header: 'Current Designation', key: 'Current Designation', width: 20 },
            { header: 'Current Location', key: 'Current Location', width: 18 },
            { header: 'Preferred Location', key: 'Preferred Location', width: 20 },
            { header: 'Current CTC', key: 'Current CTC', width: 15 },
            { header: 'Expected CTC', key: 'Expected CTC', width: 15 },
            { header: 'Experience', key: 'Experience', width: 12 },
            { header: 'Notice Period', key: 'Notice Period', width: 15 },
            { header: 'Qualification', key: 'Qualification', width: 20 },
            { header: 'Relevant Experience', key: 'Relevant Experience', width: 18 },
            { header: 'Added on', key: 'Added on', width: 18 },
            { header: 'Status', key: 'Status', width: 12 },
            { header: 'Sourcer', key: 'Sourcer', width: 15 },
            { header: 'Recruiter', key: 'Recruiter', width: 15 },
            { header: 'Last Updated on', key: 'Last Updated on', width: 18 },
            { header: 'Position Name', key: 'Position Name', width: 20 },
            { header: 'Client', key: 'Client', width: 20 },
          ];

          worksheet.columns = headers;
          // Add rows to the worksheet from JSON data
          // Set styles and formats if necessary
          worksheet.columns.forEach(column => {
            column.eachCell((cell, number) => {
              cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
              };
            });
          });
          worksheet.eachRow((row, rowNumber) => {
            row.eachCell((cell, colNumber) => {
              if (rowNumber === 1) { // For header row
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFFFCC00' },
                  bgColor: { argb: 'FF0000FF' }
                };
              }
            });
          });
          this.jobTracker.forEach(item => {
            worksheet.addRow(item);
          });

          // Write to a buffer
          //const buffer = await 
          workbook.xlsx.writeBuffer().then(buffer => {
            // Use file-saver to save the file
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Job-Tracker-${this.jobData.job_id}.xlsx`);
          });
          
        }
      })

      
    },
    async downloadClientTracker(jobId) {
      try {
        this.isDownloading = true
        const today = new Date().toISOString().split('T')[0]
        const response = await JobService.getClientTracker(jobId, today, today)
        console.log('response: ', response, response.status, response.data.data[0])
        if (response.status === 200 && response.data.data.length > 0) {
          const workbook = new Excel.Workbook();
          const worksheet = workbook.addWorksheet('Client Tracker');

          // Get headers from first data object
          const firstRow = response.data.data[0];
          const headers = Object.keys(firstRow).map(key => ({
            header: key,
            key: key,
            width: key.length * 1.5 // Adjusted width calculation
          }));

          worksheet.columns = headers;

          // Apply number format to specific columns
          worksheet.columns.forEach(column => {
            if (['Total Experience (Year.Month)', 'Relevant Experience (Year.Month)', 'Current CTC', 'Expected CTC (Hike %)', 'Notice Period'].includes(column.header)) {
              column.numFmt = '0.00';
              column.eachCell({ includeEmpty: false }, (cell, rowNumber) => {
                if (rowNumber > 1) {
                  let value = cell.value;
                  if (typeof value === 'string') {
                    // Remove any non-numeric characters except decimal point and minus
                    const numericValue = value.replace(/[^\d.-]/g, '');
                    // Check if the cleaned value is a valid number
                    if (!isNaN(numericValue) && numericValue.length > 0) {
                      cell.value = Number(numericValue);
                      cell.numFmt = '0.00';
                    }
                  }
                }
              });
            }
          });

          // Add styling and borders
          worksheet.eachRow((row, rowNumber) => {
            row.eachCell((cell, colNumber) => {
              cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
              };
              if (rowNumber === 1) {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFFFCC00' }
                };
              }
            });
          });

          // Add data rows
          response.data.data.forEach(item => {
            worksheet.addRow(item);
          });

          const buffer = await workbook.xlsx.writeBuffer();
          saveAs(
            new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 
            `client_tracker_${today}.xlsx`
          );
        } else {
          throw new Error('No data available for download')
        }
      } catch (error) {
        console.log('error: ', error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.message || 'Error downloading client tracker',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isDownloading = false
      }
    },

    showCustomDateModal() {
      this.$bvModal.show('modal-custom-date')
    },

    async handleCustomDateDownload(bvModalEvent) {
      bvModalEvent.preventDefault()

      try {
        if (!this.customDateRange.startDate || !this.customDateRange.endDate) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please select both start and end dates',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          return
        }

        this.isCustomDownloading = true // Add this loading state

        const response = await JobService.getCustomDateClientTracker(
          this.jobData.id,
          this.customDateRange.startDate,
          this.customDateRange.endDate
        )
        console.log('response: ', response)
        if (response.status === 200) {
          const workbook = new Excel.Workbook();
          const worksheet = workbook.addWorksheet('Client Tracker');

          // Get headers from response data
          const firstRow = response.data.data[0];
          const headers = Object.keys(firstRow).map(key => ({
            header: key,
            key: key,
            width: key.length * 2 // Dynamic width based on header length
          }));

          worksheet.columns = headers;

          // Apply number format to specific columns
          worksheet.columns.forEach(column => {
            if (['Total Experience (Year.Month)', 'Relevant Experience (Year.Month)', 'Current CTC', 'Expected CTC (Hike %)', 'Notice Period'].includes(column.header)) {
              column.numFmt = '0.00'; // Set number format
              column.eachCell({ includeEmpty: false }, (cell, rowNumber) => {
                if (rowNumber > 1) { // Skip header row
                  // Convert string to number, handling special cases
                  let value = cell.value;
                  if (typeof value === 'string') {
                    // Remove any non-numeric characters except decimal point and minus
                    value = value.replace(/[^\d.-]/g, '');
                  }
                  cell.value = Number(value) || 0;
                }
              });
            }
          });

          // Add header styling
          worksheet.eachRow((row, rowNumber) => {
            row.eachCell((cell, colNumber) => {
              if (rowNumber === 1) {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFFFCC00' },
                  bgColor: { argb: 'FF0000FF' }
                };
              }
            });
          });

          // Add data rows
          response.data.data.forEach(item => {
            worksheet.addRow(item);
          });

          // Generate and download file
          const buffer = await workbook.xlsx.writeBuffer();
          saveAs(
            new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 
            `client_tracker_${this.customDateRange.startDate}_to_${this.customDateRange.endDate}.xlsx`
          );

          // Clear the date range and hide modal
          this.customDateRange.startDate = ''
          this.customDateRange.endDate = ''
          this.$bvModal.hide('modal-custom-date')
        } else {
          throw new Error('Failed to download tracker')
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.message || 'Error downloading client tracker',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isCustomDownloading = false // Reset loading state
      }
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.prevRoute = from
  //   })
  // },
  updated: function () {
      this.restorePagination();
      this.restorePageSize();
      this.filterType = localStorage.getItem('filterType');
      //console.log('called in updated', this.currentpage)

      // const p_eli = document.querySelector('li.page-item.active')
      //     const p_text = p_eli.textContent 
      //     console.log('Active Element:', p_eli,p_text)
          //p_eli.classList.remove('active')
      
      // if(this.currentPage !== 1) {
      //   console.log('Page: ', this.currentPage)
      //   this.$router.push({ query: { page: this.currentPage, size: this.perPage }});
        
      //   //console.log('to: ', this.$router)
      //   // console.log('from: ', this.$router.go(-1));
      //   console.log('from: ', this.prevRoute.path);

      //   // if(this.newPage) {
      //     const p_eli = document.querySelector('li.page-item.active')
      //     const p_text = p_eli.textContent 
      //     console.log('Active Element:', p_eli,p_text)
      //     p_eli.classList.remove('active')

      //     const elb_prev = Array.from(document.querySelectorAll('button.page-link'))
      //       .find(el => el.textContent === p_text);

      //     elb_prev.setAttribute('aria-checked', false);

      //     const eli_active = Array.from(document.querySelectorAll('li.page-item'))
      //       .find(el => el.textContent === this.currentPage);
            
      //     eli_active.classList.add('active');

      //     const elb_active = Array.from(document.querySelectorAll('button.page-link'))
      //       .find(el => el.textContent === this.currentPage);

      //     elb_active.setAttribute('aria-checked', true); 
      //   }

      // } else if (this.currentPage === 1){

      // }
      this.$nextTick(function () {
        //console.log('called in updated nextTick')
        // Code that will run only after the
        // entire view has been re-rendered
        // let y = null
        const scrollPosition = localStorage.getItem('lastPosition')
        //console.log('called in updated nextTick getting localstorage', typeof scrollPosition, scrollPosition)
        if (scrollPosition !== '[object Object]') {
          const y = JSON.parse(scrollPosition).y

          
           
          setTimeout( () => {
            //console.log('called in updated nextTick after settimeout', scrollPosition, y)
            if(y) {
              window.scrollTo(0, y);
            }
          },500);
        }
        
        
      })
    },
    // mounted() {
    //   this.restorePagination();
    // },
    // updated() {
    //   this.restorePagination();
    // }
  
//   updated: function () {
//     this.$nextTick(function () {
//       // Code that will run only after the
//       // entire view has been re-rendered
      
//       const scrollPosition = localStorage.getItem('lastPosition')
//       //console.log('called',JSON.stringify(scrollPosition))
//       ////console.log('last position', JSON.parse(scrollPosition).y  )
//       window.scrollTo(0, scrollPosition.y);
//     })
//   },
//   mounted() {
// //     window.addEventListener('scroll', () => {
// //     const scrollPosition = window.scrollY || document.documentElement.scrollTop;
// //     // Save to Vuex state
// //     this.$store.commit('setScrollPosition', scrollPosition);
// //     // Or save to local storage
// //     localStorage.setItem('scrollPosition', scrollPosition);
// // });
//   }
};
</script>

<style scoped>
.demo-inline-spacing > * {
  margin-top: 0.1rem !important;
}
.b-avatar {
  width: 2.5rem !important;
  height: 2.5rem !important;
  font-size: 1.05rem !important;
}
.card-header {
  padding: 0.5rem !important;
}
.text-muted {
  color: #9c9c9f !important;
}
.col {
  padding-right: 0.1rem;
  padding-left: 0.1rem;
}

.vs__dropdown-toggle {
    padding: 0 0 4px 0;
    background: none;
    transform-style: preserve-3d;
    border-radius: 5px;
    padding: 0rem 5rem;
    box-shadow: 0 2px 2px rgb(0 0 0 / 25%), 0px 0px 2px rgb(0 0 0 / 20%);
}

</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.table.b-table > tbody > .table-active, [dir] .table.b-table > tbody > .table-active > th, [dir] .table.b-table > tbody > .table-active > td {
    background-color: #6cd2c6;
}
</style>
<style lang="scss" scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
</style>
